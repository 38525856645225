.sidebarWrapper {
    border-right: 1px lightgrey solid;
    min-height: 100vh;
    height: 100%;
    background-color: white;
}

.replies_table {
    border-collapse: collapse;
    width: 100%;
}

.replies_table tr:nth-child(even) {
    background-color: #fafafa;
}

.replies_table th {
    text-align: left;
    background-color: #ffffff;
    color: black;
}

.replies_table td,
.replies_table th {
    padding: 0.8rem;
    height: 4rem;
    vertical-align: middle;
}


.tile {
    background-color: white;
    padding:1rem;
    border-radius: 0.5rem;
    height:100%;
}

.layout_sidebar {
    position: sticky;
    top: 0;
    overflow: hidden;
    max-height:calc(100vh - 4.1rem);
}

.layout_content {
    padding:1rem;
    max-height:calc(100vh - 6.1rem);
    overflow-y:scroll;
}

