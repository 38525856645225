.wrapper {
    height: 4rem;
    background-color: white;
    border-bottom: 1px lightgrey solid;
    padding: 0 1rem 0 1rem;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; 
}

.logo {
    height: 4rem;
    max-width: 100%; 
    object-fit: contain; 
}