.replies_table {
    border-collapse: collapse;
    width: 100%;
}

.replies_table tr:nth-child(even) {
    background-color: #fafafa;
}

.replies_table th {
    text-align: left;
    background-color: #ffffff;
    color: black;
}

.replies_table td,
.replies_table th {
    padding: 0.8rem;
    height: 2.3rem;
    vertical-align: middle;
}

.tiles {
    height: 100%;
    background-color: #ffffff;
    border-radius: 0.4rem;
    overflow: hidden;
}

.tileheader {
    background-color: rgba(255, 255, 255, 0.915);
    height:2rem;
    padding:0.4rem 0.4rem 0.4rem 1rem;
}

.metricTile {
    height:100%;
    background-color: white;
    padding:0.5rem;
    border-radius:0.3rem;
}