.wrapper {
    width: 40px;
    margin: 0 1rem 0rem 0;
    cursor: pointer;
    border:1px transparent solid;

}

.clickedButton {
    border:1px transparent solid;
    width: 40px;
    margin: 0 1rem 1rem 0;
    cursor: pointer;
}
